import axios from 'axios';

const request = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: 'application/json',
  },
});

const strapi = axios.create({
  baseURL: process.env.VUE_APP_STRAPI,
  headers: {
    Accept: 'application/json',
  },
});

export default {
  strapi,
  instance: request,
  call: function(method, data, config) {
    return request.post(`v1/gw/rpc?method=${encodeURIComponent(method)}`, data, config);
  }
};
